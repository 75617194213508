import { computed, effect, Inject, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthState } from '@logic-suite/shared/auth/auth-state.enum';
import { createAuthConfig } from '@logic-suite/shared/auth/auth.config';
import { OAuthEventService } from '@logic-suite/shared/auth/o-auth-event/o-auth-event.service';
import { OAuthWrapperService } from '@logic-suite/shared/auth/o-auth-wrapper/o-auth-wrapper.service';
import { EnvService } from '@logic-suite/shared/env/env.service';
import { Logger } from '@logic-suite/shared/logger/logger.service';
import { TokenResponse } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  shareReplay,
  take,
  throwError,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { clearCacheOnly, clearSWOnly, errorToString, titleCase } from '../utils';
import { getLocalStorage } from '../utils/getLocalStorage';
import { AUTH_ENV, AUTH_TOKEN } from './auth.token';

const e2eTest = 'Cypress' in window;

interface LoginOptions {
  returnTo?: string;
  idp?: string;
  login?: string;
}

interface KCLoginOptions {
  kc_idp_hint?: string;
  login_hint?: string;
}

interface AuthUser {
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  roles: string[];
}

interface AuthServiceState {
  authState: AuthState;
}

/**
 * Service bridge between our application and the OIDC library
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly oAuthWrapperService = inject(OAuthWrapperService);
  private readonly oAuthEventService = inject(OAuthEventService);
  private readonly envService = inject(EnvService);
  private readonly logger = inject(Logger);
  private readonly router = inject(Router);
  private readonly snack = inject(MatSnackBar);

  // sources
  // TODO(bjhandeland): Change to regular Subject when dependencies use authState signal instead.
  authState$ = new BehaviorSubject<AuthState>(AuthState.NOT_ACTIVE);

  // state
  private state = signal<AuthServiceState>({
    authState: AuthState.NOT_ACTIVE,
  });

  // selectors
  authState = computed(() => this.state().authState);
  isLoggedIn = computed(() => this.authState() === AuthState.AUTHENTICATED);

  isLoggedIn$ = this.authState$.pipe(
    filter((state) => state >= AuthState.NEEDS_LOGIN),
    map(() => {
      if (e2eTest) return true;
      return this.oAuthWrapperService.hasValidAccessToken();
    }),
  );

  // Used by silent refresh
  private tokenFetch$?: Observable<string>;

  private handleLoginRequired$ = this.oAuthEventService.errorEvent$.pipe(
    filter((event) => (event.params as any)?.error === 'login_required'),
    tap(() => {
      if (getLocalStorage().getItem('disableRefresh') !== 'true') {
        firstValueFrom(this.getTokenSilently$(true)).then(() => {
          if (!this.oAuthWrapperService.hasValidAccessToken()) {
            this.router.navigate(['/logout']);
          }
        });
      }
    }),
  );

  private handleSilentRefreshTimeout$ = this.oAuthEventService.errorEvent$.pipe(
    filter((event) => event.type === 'silent_refresh_timeout'),
    tap(() => {
      firstValueFrom(this.getTokenSilently$(true));
    }),
  );

  constructor(@Inject(AUTH_TOKEN) private authToken: AUTH_ENV) {
    this.authState$.pipe(takeUntilDestroyed()).subscribe((authState) => {
      this.state.update((state) => ({
        ...state,
        authState: authState,
      }));
    });

    effect(() => {
      this.logger.debug(`AuthState: ${AuthState[this.authState()]}`, 'AuthService');
    });

    this.handleLoginRequired$.pipe(takeUntilDestroyed()).subscribe();
    this.handleSilentRefreshTimeout$.pipe(takeUntilDestroyed()).subscribe();
  }

  /**
   * Parse user info from token
   * @returns
   */
  getUser(): AuthUser {
    const token = this.oAuthWrapperService.getAccessToken();
    const access = !token ? {} : JSON.parse(atob(token.split('.')[1]));
    return {
      email: access.email,
      family_name: access.family_name,
      given_name: access.given_name,
      name: access.name,
      roles: access.realm_access?.roles ?? [],
    };
  }

  /**
   * @param roles
   * @returns true if access token contains any of the given roles
   */
  hasRoles(roles: string[]) {
    const userRoles = this.getUser()?.roles;
    return userRoles?.some((r: string) => roles.some((gr) => r.toLowerCase().includes(gr.toLowerCase())));
  }

  async initAuth(): Promise<boolean> {
    // Make sure we only initialize once
    if (this.authState() > AuthState.INITIALIZING) return firstValueFrom(this.isLoggedIn$);

    // Support for E2E testing with Cypress. Must mock all api calls for this to work.
    if (e2eTest) {
      this.authState$.next(AuthState.AUTHENTICATED);
      return Promise.resolve(true);
    }

    // Set initial state
    this.authState$.next(AuthState.INITIALIZING);

    // Configure auth service and initialize
    const authConfig = createAuthConfig(this.authToken, this.envService.isDev());
    this.oAuthWrapperService.configure(authConfig);

    // Setup automatic silent refresh
    this.oAuthWrapperService.setupAutomaticSilentRefresh();

    /*
     * Try to load the discovery document and login.
     * If loading the discovery document fails, it will retry a maximum of 4 times.
     *   - First time: just reload the page.
     *   - Second time: Clear SW cache and reload page.
     *   - Third time: Unregister SW and reload page.
     *   - Fourth time: Present info to user and die!
     */
    const KEY = 'login_retries';
    try {
      // Load discovery document
      const success = await this.oAuthWrapperService.loadDiscoveryDocument();
      if (!success) throw new Error('Failed to load discovery document');
      this.authState$.next(AuthState.INITIALIZED);

      // We have a discovery document. Try to login
      this.authState$.next(AuthState.PROCESSING);
      await this.oAuthWrapperService.tryLoginCodeFlow();
      this.authState$.next(AuthState.PROCESSED);

      // Get or refresh the access token if we have a valid session
      await firstValueFrom(this.getTokenSilently$());

      // Check if we are logged in
      if (this.oAuthWrapperService.hasValidAccessToken()) {
        // Decode token and store logged in user
        const token = this.oAuthWrapperService.getAccessToken();
        const { email } = JSON.parse(atob(token.split('.')[1]));
        if (email) getLocalStorage().setItem('user', email);

        // We are logged in!
        this.authState$.next(AuthState.AUTHENTICATED);

        // If we have previous state before login, redirect to that url
        if (this.oAuthWrapperService.state) {
          const url = decodeURIComponent(this.oAuthWrapperService.state);
          setTimeout(() => location.replace(url));
        }
      } else {
        // We are not logged in. Redirect to login page
        this.authState$.next(AuthState.NEEDS_LOGIN);
        this.router.navigate(['/logout']);
      }

      // We are done!
      sessionStorage.removeItem(KEY);
      return Promise.resolve(true);
    } catch (ex) {
      const times = Number(sessionStorage.getItem(KEY) || 0);
      if (times > 3) {
        // Tried more than 3 times. Give up. :-(
        this.snack.open('Failed to login. Please try again later.', 'Ok', { duration: 15000 });
        sessionStorage.removeItem(KEY);
        return Promise.resolve(false);
      }
      if (times >= 1) {
        // Clear service worker cache if first reload did not pan out.
        clearCacheOnly();
      }
      if (times >= 2) {
        // If still faulty, unregister the entire service worker
        // It will be reinstalled automatically on next reload
        clearSWOnly();
      }

      // Count the times this has crashed and reload the page
      sessionStorage.setItem(KEY, `${times + 1}`);
      const url = location.href;
      setTimeout(() => (location.href = url), 1000 * times);
      return Promise.resolve(false);
    }
  }

  /**
   * Called by the login component when the user clicks the login button.
   *
   * @param options
   * @returns
   */
  async login(options: LoginOptions = {}): Promise<boolean> {
    if (e2eTest) {
      this.authState$.next(AuthState.AUTHENTICATED);
      return Promise.resolve(true);
    }

    if (this.authState() < AuthState.INITIALIZED) {
      try {
        return await this.initAuth();
      } catch (ex) {
        this.snack.open(errorToString(ex), 'Ok', { duration: 5000 });
        return Promise.resolve(false);
      }
    }

    const loginOptions = this.processLoginOptions(options);
    const kcLoginOptions = this.loginOptionsToKCLoginOptions(loginOptions);
    try {
      this.oAuthWrapperService.initCodeFlow(loginOptions?.returnTo ?? undefined, kcLoginOptions);
      return Promise.resolve(true);
    } catch (ex) {
      this.snack.open(errorToString(ex), 'Ok', { duration: 5000 });
      return Promise.resolve(false);
    }
  }

  /**
   * This can be called by the user during an active logout,
   * it can be called by the application when the token is
   * no longer valid or it can be called when backend responds
   * with a 401 message.
   *
   * In the latter case, the client and backend are out of sync
   * and we need to re-login to get a new token.
   * @returns
   */
  async logout(): Promise<boolean> {
    if (e2eTest) return true;

    try {
      await this.oAuthWrapperService.revokeTokenAndLogout(true);
      this.logger.info('Logged out.', 'AuthService');
    } catch (ex) {
      this.oAuthWrapperService.logOut(true);
    }
    this.authState$.next(AuthState.NEEDS_LOGIN);
    return this.isLoggedIn();
  }

  /**
   * If traces of last logged in user is found in localStorage, try to do a silent login.
   * This is a workaround for the login issue we have with KeyCloak.
   *
   * The response from this process can be null if the IDP responds with an error. In that
   * case logout.
   *
   * @returns a faux token response
   */
  tryAutoLoginLastUser() {
    const lastUser = getLocalStorage().getItem('user') ?? undefined;
    if (lastUser) {
      const domain = lastUser.split('@')[1].split('.')[0].toLowerCase();
      return firstValueFrom(
        from(this.login({ idp: `${titleCase(domain)}AD`, login: lastUser })).pipe(
          catchError(() => {
            this.logout();
            return throwError(() => ({ access_token: null }) as unknown as TokenResponse);
          }),
          map(() => ({ access_token: this.oAuthWrapperService.getAccessToken() }) as unknown as TokenResponse),
        ),
      );
    }
    return Promise.resolve({ access_token: null } as unknown as TokenResponse);
  }

  private processLoginOptions(options: LoginOptions = {}): LoginOptions {
    if (!options.login) {
      options.login = getLocalStorage().getItem('user') || undefined;
    }
    if (options.login && !options.idp) {
      options.idp = `${titleCase(options.login.split('@')[1].split('.')[0].toLowerCase())}AD`;
    }
    return options;
  }

  private loginOptionsToKCLoginOptions(options: LoginOptions): KCLoginOptions {
    return {
      ...(options.idp && { kc_idp_hint: options.idp }),
      ...(options.login && { login_hint: options.login }),
    };
  }

  /**
   * Check access token expiry. If we are close to expiry, the
   * automatic silent refresh has not done it's job and we need to
   * manually refresh.
   *
   * @returns The access token
   */
  getTokenSilently$(refresh = false): Observable<string> {
    if (!this.tokenFetch$) {
      const token = this.oAuthWrapperService.getAccessToken();
      if (e2eTest) return of('NOT_A_TOKEN');

      // A valid token must 1) not be expired and 2) belong to the correct issuer
      let hasValidToken = false;
      if (token != null) {
        const access = !token ? {} : JSON.parse(atob(token.split('.')[1]));
        hasValidToken =
          this.oAuthWrapperService.hasValidAccessToken() === true &&
          access.iss === `${this.authToken.issuer}${this.authToken.tenantId}`;
      }
      if (!hasValidToken || refresh === true) {
        // Token is invalid, about to expire or we are forcing a refresh.
        // Try to refresh the token or autologin the last user if the refresh token is also missing.
        const hasRefreshToken = this.oAuthWrapperService.getRefreshToken() != null;
        this.logger.debug(
          hasRefreshToken ? 'Token is about to expire. Refreshing...' : 'Not logged in. Trying autologin...',
          'AuthService',
        );
        this.tokenFetch$ = (
          hasRefreshToken
            ? from(this.oAuthWrapperService.refreshToken()).pipe(
                shareReplay(1),
                catchError(() => from(this.tryAutoLoginLastUser())),
              )
            : from(this.tryAutoLoginLastUser())
        ).pipe(
          map((e) => e.access_token),
          take(1),
        );
      } else {
        // Token is valid. Return it.
        this.tokenFetch$ = of(token);
      }
    }
    return this.tokenFetch$.pipe(finalize(() => (this.tokenFetch$ = undefined)));
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getLocalStorage } from '../utils/getLocalStorage';
import { AuthService } from './';

@Component({
    selector: 'lib-logout',
    template: '<mat-spinner></mat-spinner>',
    styles: [
        `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 4rem);
      }
    `,
    ],
    standalone: false
})
export class LogoutComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      // User explicitly wants to log out. Remove all traces.
      this.auth
        .logout()
        .then(() => getLocalStorage().removeItem('user'))
        .finally(() => this.router.navigate(['/']));
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
